<template>
  <b-form-group :label="label">
    <vue-select
      :value="value"
      :options="options"
      :getOptionLabel="optionLabel"
      :disabled="disabled"
      :filterBy="filterBy"
      :placeholder="placeholder"
      @input="(selectValue) => $emit('input', selectValue)"
    >
      <template #open-indicator="{ attributes }"
        ><span v-bind="attributes"><svg-caret-icon /></span
      ></template>
    </vue-select>
  </b-form-group>
</template>

<script>
import "vue-select/dist/vue-select.css";

import normalize from "/utils/normalize";

export default {
  components: {
    "vue-select": () => import("vue-select"),
    "svg-caret-icon": () => import("/assets/icons/caret.svg?inline"),
  },

  props: {
    label: { type: String, default: "" },
    value: { required: true },
    options: { required: true },
    placeholder: { required: true },
    disabled: { required: false, default: false },
    optionLabel: { required: true },
  },

  methods: {
    filterBy(option, label, search) {
      return normalize(label).indexOf(normalize(search)) > -1;
    },
  },
};
</script>
